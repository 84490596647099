<template>
    <div class="col-12 g">
        <div class="card card-body">
            <div class="col-12">
                <div class="col-12 col-lg-4 g">
                    <span>البحث</span>
                    <input type="text" placeholder="ابحث هنا..." class="form-control" v-model="q" @keyup="page = 0; getArchive();">
                </div>
            </div>
            <img :src="require('@/assets/images/loading.svg')" alt="" width="50" v-if="loading">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-striped table-bordered" id="headerTable">
                    <thead>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الجوال
                        </th>
                        <th>
                            تاريخ الانضمام
                        </th>
                        <th>
                            التفعيل
                        </th>
                        <th>
                            الخدمات
                        </th>
                        <th>
                            كود التسويق
                        </th>
                        <th>
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="user in users" :key="user._id">
                            <td>
                                {{ user.name }}
                            </td>
                            <td>
                                {{ user.phone }}
                            </td>
                            <td>
                                {{ user.joindate }}
                            </td>
                            <td>
                                <span v-if="!user.activated" class="text-danger">
                                    <i class="fa fa-times"></i>
                                    {{ user.otp }}
                                </span>
                                <span v-if="user.activated" class="text-success">
                                    <i class="fa fa-check"></i>
                                </span>
                                <span v-if="user.banned" class="text-danger">[محظور]</span>
                            </td>
                            <td>
                                <button class="btn btn-primary btn-sm" style="border-radius: 2px;" v-b-modal.services @click="selectedUser = user; getServices()">
                                    <i class="fa fa-cogs"></i>
                                    عرض
                                    الخدمات
                                </button>
                            </td>
                            <td>
                                {{ user.code }}
                            </td>
                            <td>
                                <button class="btn btn-success btn-sm" style="border-radius: 2px;" v-b-modal.settings @click="selectedUser = user;">
                                    <i class="fa fa-list"></i>
                                    خيارات
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <button class="btn btn-danger" @click="page--; getArchive()" v-if=" page > 0 && !loading">الصفحة السابقة <i class="fa fa-arrow-right"></i></button>&nbsp;
                <button class="btn btn-primary" @click="page++; getArchive()" v-if="users.length > 98 && !loading">الصفحة التالية <i class="fa fa-arrow-left"></i></button>
            </div>
        </div>
        <b-modal id="services" hide-footer size="lg">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>
                            تمكين
                        </th>
                        <th>
                            الخدمة
                        </th>
                        <th>
                            تاريخ الانتهاء
                        </th>
                        <th>
                            العدد المسموح به
                        </th>
                        <th>
                            تجريبي
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="s in services" :key="s.code">
                            <td>
                                <button class="btn btn-sm btn-success" v-if="!s.enabled" @click="s.enabled = true">
                                    <i class="fa fa-check"></i>
                                    تمكين
                                </button>
                                <button class="btn btn-sm btn-danger" v-if="s.enabled" @click="s.enabled = false">
                                    <i class="fa fa-times"></i>
                                    ازالة الخدمة
                                </button>
                            </td>
                            <td :class="s.enabled ? '' : 'text-muted'">
                                {{ s.title }}
                            </td>
                            <td :class="s.enabled ? '' : 'text-muted'">
                                <input type="text" class="form-control form-control-sm" style="min-width: 100px" dir="ltr" v-model="s.exdate" v-if="s.enabled">
                            </td>
                            <td :class="s.enabled ? '' : 'text-muted'">
                                <input type="text" class="form-control form-control-sm" style="min-width: 100px" dir="ltr" v-model="s.limit" v-if="s.enabled">
                            </td>
                            <td :class="s.enabled ? '' : 'text-muted'">
                                <div class="form-check" v-if="s.enabled">
                                  <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" v-model="s.demo" :value="true">
                                    تجريبي
                                  </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="col-12 text-center g">
                    <button class="btn btn-lg btn-primary" @click="saveservices()">
                        <i class="fa fa-save"></i>
                        حفظ التعديلات
                    </button>
                </div>
            </div>
        </b-modal>
        <b-modal id="settings" hide-footer size="sm">
            <div class="g">
                <button class="btn btn-success btn-block" @click="join()">
                    <i class="fa fa-external-link"></i>
                    دخول بحسابه
                </button>
                <button class="btn btn-info btn-block" @click="$router.push('/devices#' + selectedUser._id)">
                    <i class="fa fa-list"></i>
                    اجهزة البصمة
                </button>
                <button class="btn btn-primary btn-block" @click="password()">
                    <i class="fa fa-key"></i>
                    تعديل كلمة المرور
                </button>
                <button class="btn btn-warning btn-block" @click="phone()">
                    <i class="fa fa-mobile"></i>
                    تعديل رقم الجموال
                </button>
                <button class="btn btn-danger btn-block" v-if="!selectedUser.banned" @click="bantoggle()">
                    <i class="fa fa-ban"></i>
                    حظر المستخدم
                </button>
                <button class="btn btn-success btn-block" v-if="selectedUser.banned" @click="bantoggle()">
                    <i class="fa fa-ban"></i>
                    فك 
                    الحظر
                </button>
                <hr>
                <button class="btn text-danger btn-block" @click="deleteUser()">
                    <i class="fa fa-trash"></i>
                    حذف الحساب
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            loading: true,
            loading2: true,
            jwt: localStorage.getItem('jwt'),
            users: [],
            q: "",
            page: 0,
            services: [],
            selectedUser: {}
        }
    },
    created(){
        var g = this;
        g.getArchive();
    },
    methods: {
        getArchive(){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/users/list', {
                jwt: this.jwt,
                q: this.q,
                page: this.page,
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.users = r.response
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        getServices(){
            var g = this;
            g.services = [];
            $.post(api + '/admin/users/services', {
                jwt: this.jwt,
                id: this.selectedUser._id
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.services = r.response
                }
            }).fail(function(){
            })
        },
        saveservices(){
            var g = this;
            $.post(api + '/admin/users/services-save', {
                jwt: this.jwt,
                id: this.selectedUser._id,
                services: this.services
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    $("#services___BV_modal_header_ > button").click()
                }
            }).fail(function(){
            })
        },
        join(){
            window.open("https://s.tahdir.net/autologin/" + this.selectedUser.jwt)
        },
        deleteUser(){
            var g = this;
            if(confirm("متأكد من حذف الحساب؟")){
                $.post(api + '/admin/users/delete', {
                    jwt: this.jwt,
                    id: this.selectedUser._id
                }).then(function(r){
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        $("#settings___BV_modal_header_ > button").click()
                        g.getArchive()
                    }
                }).fail(function(){
                })
            }
        },
        bantoggle(){
            var g = this;
            $.post(api + '/admin/users/toggle-banned', {
                jwt: this.jwt,
                id: this.selectedUser._id
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    $("#settings___BV_modal_header_ > button").click()
                    g.getArchive()
                }
            }).fail(function(){
            })
        },
        password(){
            var g = this;
            var password = prompt("كلمة المرور", this.selectedUser.password)
            if(password){
                $.post(api + '/admin/users/edit-password', {
                    jwt: this.jwt,
                    id: this.selectedUser._id,
                    password: password
                }).then(function(r){
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        $("#settings___BV_modal_header_ > button").click()
                        g.getArchive()
                    }
                }).fail(function(){
                })
            }
        },
        phone(){
            var g = this;
            var password = prompt("الجوال", this.selectedUser.phone)
            if(password){
                $.post(api + '/admin/users/edit-phone', {
                    jwt: this.jwt,
                    id: this.selectedUser._id,
                    phone: password
                }).then(function(r){
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        $("#settings___BV_modal_header_ > button").click()
                        g.getArchive()
                    }
                }).fail(function(){
                })
            }
        },
    }
}
</script>
<style scoped>
.table,th,td,tr{
    white-space: nowrap !important;
}
</style>